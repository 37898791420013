import { Component, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { CogeoService } from '../../services/cogeo/cogeo.service';
import { CogeoMessageAnswer } from '../../models/cogeo-message.answer.model';
import { CogeoConversation } from '../../models/cogeo-conversation.model';
import { TimeComment } from '../../models/time-comment.model';
import { FileService } from '../../services/file/file.service';
import { cogeoConversationType } from '../../enums/cogeo-conversation-type.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
import { EMPTY, Subscription } from 'rxjs';
import { WorkTaskService } from 'src/app/services/training/work-task.service';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

declare var webkitSpeechRecognition: any;
declare var SpeechRecognition: any;
@Component({
  selector: 'app-cogeo-chat',
  templateUrl: './cogeo-chat.component.html',
  styleUrls: ['./cogeo-chat.component.css'],
  providers: [CogeoService]
})
export class CogeoChatComponent implements OnDestroy {
  taskData: any;
  userAnswer: string = '';
  conversation: CogeoConversation[] = [];
  isUserMessageLocked: boolean = true;
  isTyping: boolean = false;
  typingDelay: number = 50;
  lastTranscript: string = '';
  userInputControl = new FormControl({
    value: '',
    disabled: true
  });


  public isSpeechRecognationActivatedByUser: boolean = false;
  public typingInterval: any;
  public isVoiceRecognitionStarted : boolean = false;
  private isVoiceRecognitionAvailable:boolean = 'webkitSpeechRecognition' in window || 'speechRecognition' in window;
  private recognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
  public isRecording: boolean = false;
  private subscriptions: Subscription[] = [];
  private audioInstances: HTMLAudioElement[] = [];
  private isChatEnded: boolean = false;
  @Input() workTaskFileId: string = "";
  @Input() workTaskId: string = "";
  @Input() professionId: string = "";
  @Input() cogeoTaskId: number = -1;
  @ViewChild('scrollTarget') private scrollTarget!: ElementRef;
  @ViewChild('userInput') userInput!: ElementRef<HTMLTextAreaElement>;


  constructor(private cogeoService: CogeoService, private workTaskService: WorkTaskService,  private fileService: FileService, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
    this.userInputControl.valueChanges.subscribe(value => {
      if (value) {
        this.onUserAnswerChange(value);
      }
    });
  }
  ngOnInit(): void {
    if (this.isValidParams()) {
      this.fetchTaskDetail();
    }
    const chatEndSubscription = this.cogeoService.chatEnded$.subscribe(() => {
      this.isChatEnded = true;
      this.isUserMessageLocked = true;
      this.userAnswer = '';
      this.stopSpeechRecognition();
    });
    this.subscriptions.push(chatEndSubscription);
  }

  private isValidParams(): boolean {
    return this.workTaskFileId !== "" && this.workTaskId !== "" && this.professionId !== "";
  }

  private fetchTaskDetail(): void {
    this.workTaskService.getTaskDetail(this.workTaskId).pipe(
      tap(task => {
        if (task.cogeoTaskId !== undefined) {
          this.cogeoTaskId = task.cogeoTaskId;
        }
      }),
      switchMap(task => {
        if (this.cogeoTaskId > 0) {
          this.cogeoService.showFinalPopin(this.professionId, this.workTaskId, this.workTaskFileId, this.cogeoTaskId);
          return EMPTY;
        }
        this.initSpeechToTextIfAvailable();
        return this.fileService.getComments(this.workTaskFileId);
      }),
      filter(comments => comments.length > 0),
      tap(comments => {
        this.initializeAnalysis(this.professionId, this.workTaskId, this.workTaskFileId, comments);
      }),
      mergeMap(() => this.cogeoService.messages$)
    ).subscribe({
      next: message => this.handleIncomingMessages(message),
      error: error => console.error("Erreur lors du traitement des données", error)
    });
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    if (this.typingInterval) {
      clearInterval(this.typingInterval);
    }

    if (this.isVoiceRecognitionStarted) {
      this.stopSpeechRecognition();
    }

    this.audioInstances.forEach(audio => {
      audio.pause();
      audio.src = '';
      URL.revokeObjectURL(audio.src);
    });
    this.audioInstances = [];
  }

  private scrollToBottom(): void {
    this.cdr.detectChanges();
    if (this.scrollTarget) {
      this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'auto' });
    }
  }

  private addQuestionWithTypingEffect(question: string): void {
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Question, ""));
    let index = 0;
    this.isTyping = true;
    this.isUserMessageLocked = true;
    this.typingInterval = setInterval(() => {
      if (index < question.length) {
        this.conversation[this.conversation.length - 1].text += question[index];
        index++;
      } else {
        this.isTyping = false;
        clearInterval(this.typingInterval);
        this.isUserMessageLocked = false;
      }
      this.scrollToBottom();
    }, this.typingDelay);
}


  private initializeAnalysis(professionId: string, workTaskId: string, workTaskFileId: string, comments: TimeComment[]): void {
    this.isUserMessageLocked = true;
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Processing, "..."));
    this.cogeoService.initializeAnalysis(professionId, workTaskId, workTaskFileId,comments);
  }

  private replaceCharactersWithDots(input: string): string {
    return input.replace(/[^ \n]/g, '.');
  }

  private removeAudioInstance(audio: HTMLAudioElement): void {
    const index = this.audioInstances.indexOf(audio);
    if (index > -1) {
      this.audioInstances.splice(index, 1);
    }
  }

  private handleIncomingMessages(message: CogeoMessageAnswer): void {
    if (message.type === "stream"|| !message.question?.trim()){
      if (!this.conversation[this.conversation.length - 1].isStream){
        this.conversation = this.conversation.filter(message => message.type !== "processing");
        this.conversation.push(new CogeoConversation(true, cogeoConversationType.Question, this.replaceCharactersWithDots(message.content)));
      }
      else{
        this.conversation[this.conversation.length - 1].text +=  this.replaceCharactersWithDots(message.content);
      }
      this.scrollToBottom();
    }
    else
    {
      if ((message.question == undefined || message.question =="") && message.complete_question =="")
        {
          return;
        }
        this.conversation = this.conversation.filter(message => message.type !== "processing");
        this.taskData = message;
        var question = "";
        if (this.taskData.question != undefined)
        {
          question = this.taskData.question;
        }

        if (this.taskData.complete_question != undefined)
        {
          question = this.taskData.complete_question;
        }

        if (question == ""){
          return;
        }


        if (this.conversation.length>=1 && this.conversation[this.conversation.length - 1].isStream){
          var lastMessage =  this.conversation[this.conversation.length - 1];
          lastMessage.text = question;
          lastMessage.type = cogeoConversationType.Question;
          lastMessage.isStream = false;
        }
        else
        {
          this.conversation = this.conversation.filter(msg => msg.type !== "processing");
          if (!this.isTyping) {
            this.userInputControl.enable();
            this.isUserMessageLocked = false;
          }
          this.addQuestionWithTypingEffect(question);
        }
        this.isUserMessageLocked = false;
        this.scrollToBottom();
        this.cogeoService.synthesizeText(question).subscribe({
          next: async (audioBlob) => {
            const audioShift = 0.8;
            const audioUrl = URL.createObjectURL(audioBlob);
            const audioContext = new AudioContext();

            try {
              const audioBuffer = await fetch(audioUrl)
                .then((response) => response.arrayBuffer())
                .then((buffer) => audioContext.decodeAudioData(buffer));

              // Add a silence on the beginning of the audio
              const silenceBuffer = audioContext.createBuffer(
                1,
                audioContext.sampleRate * audioShift,
                audioContext.sampleRate
              );

              const silenceSource = audioContext.createBufferSource();
              silenceSource.buffer = silenceBuffer;

              const audioSource = audioContext.createBufferSource();
              audioSource.buffer = audioBuffer;

              silenceSource.connect(audioContext.destination);
              audioSource.connect(audioContext.destination);

              silenceSource.start(0);
              audioSource.start(audioContext.currentTime + audioShift);

              audioSource.onended = () => {
                this.startSpeechRecognition();
                URL.revokeObjectURL(audioUrl);
              };

              this.stopSpeechRecognition();
            } catch (err) {
              console.error('Error decoding or playing audio', err);
            }
          },
          error: (err) => {
            console.error('Error synthesizing text', err);
          },
        });
      this.userAnswer = '';
    }

  }

  private initSpeechToTextIfAvailable() {
    const acceptedSendWords = ['envoi', 'envoyer', 'envoie', 'envoyé', 'envoyée', 'ninja'];
    let completeTranscript = '';
    let currentSession = '';
    let isStarting = false;

    if (this.isVoiceRecognitionAvailable) {
      if ('webkitSpeechRecognition' in window) {
        this.recognition = new webkitSpeechRecognition();
      } else {
        this.recognition = new SpeechRecognition();
      }

      this.recognition.lang = "fr-FR";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;

      this.recognition.onstart = () => {
        console.log('Recognition started');
        this.isRecording = true;
        isStarting = false;
        currentSession = '';
        completeTranscript = '';
      };

      this.recognition.onresult = (event: any) => {
        if (this.isVoiceRecognitionStarted) {
          let interimTranscript = '';

          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript;
            console.log('Current transcript:', transcript);

            if (event.results[i].isFinal) {
              completeTranscript += transcript + ' ';

              // Check for send word without converting to lowercase first
              const words = completeTranscript.trim().split(' ');
              const lastWord = words[words.length - 1];
              console.log('Checking last word:', lastWord);

              if (lastWord && acceptedSendWords.includes(lastWord.toLowerCase())) {
                console.log('Send word detected:', lastWord);

                const messageWords = completeTranscript.trim().split(' ');
                messageWords.pop();
                const messageToSend = messageWords.join(' ').trim();

                console.log('Final message to send:', messageToSend);

                // Set the value in the input control
                this.userInputControl.setValue(messageToSend);
                console.log('User input control value:', this.userInputControl.value);

                this.stopSpeechRecognition();
                this.sendMessage();
                completeTranscript = '';

                return;
              }
            } else {
              interimTranscript += transcript;
            }

            // Update the text area with the interim transcript more frequently
            this.userInputControl.setValue(completeTranscript + interimTranscript);
          }
        }
      };

      this.recognition.onend = () => {
        console.log('Recognition ended');
        this.isRecording = false;
        isStarting = false;
      };

      this.recognition.onerror = (event: any) => {
        console.error('Recognition error:', event.error);
        isStarting = false;
        this.isRecording = false;
      };
    }
  }


  public sendMessage(): void {
    // Prevent sending if typing or empty message
    if (this.isChatEnded || this.isTyping || !this.userInputControl.value?.trim()) {
      return;
    }

    // Lock input while processing
    this.userInputControl.disable();
    this.isUserMessageLocked = true;

    // Add message to conversation
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Answer, this.userInputControl.value));
    this.conversation.push(new CogeoConversation(false, cogeoConversationType.Processing, "..."));

    // Send to service and reset input
    this.cogeoService.sendMessage(this.userInputControl.value);
    this.userInputControl.setValue('');
    this.resetTextareaHeight();
    this.scrollToBottom();
  }



  public startSpeechRecognitionByUser()
  {
    this.isSpeechRecognationActivatedByUser = true;
    this.startSpeechRecognition();
  }

  public stopSpeechRecognitionByUser()
  {
    this.isSpeechRecognationActivatedByUser = false;
    this.stopSpeechRecognition();
  }

  public startSpeechRecognition()
  {
    if (!this.isSpeechRecognationActivatedByUser)
    {
      return;
    }
    this.recognition.start();
    this.isVoiceRecognitionStarted = true;
  }

  public stopSpeechRecognition()
  {
    this.recognition.stop();
    this.isVoiceRecognitionStarted = false;
  }

  public formatText(text: string) {
    const formattedText = text.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }

  private focusTextareaToEnd(): void {
    if (this.userInput) {
      const textarea = this.userInput.nativeElement;
      const textLength = textarea.value.length;
      textarea.setSelectionRange(textLength, textLength);
      textarea.focus();
    }
  }

  public adjustTextareaHeight(): void {
  if (this.userInput) {
    const textarea = this.userInput.nativeElement;
    textarea.style.height = 'auto';
    const newHeight = Math.min(textarea.scrollHeight, 300);
    textarea.style.height = `${newHeight}px`;
  }
  }

  private resetTextareaHeight(): void {
    if (this.userInput) {
      const textarea = this.userInput.nativeElement;
      textarea.style.height = '100px';
    }
  }

  public onUserAnswerChange(value: string): void{
    this.lastTranscript = value;
    this.adjustTextareaHeight();
  }

  public preventFocus(event: FocusEvent): void {
    if (this.isVoiceRecognitionStarted){
      event.preventDefault();
    }
  }

}
