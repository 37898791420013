import { Component, OnInit } from '@angular/core';
import { ProfessionService } from '../../../../services/training/profession.service';
import { ActivatedRoute } from '@angular/router';
import { WorkTask } from '../../../../models/work-task.model';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WorkTaskService } from '../../../../services/training/work-task.service';
import { forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { WorkTaskFilter } from '../../../../models/work-task-filter.model';
import { Profession } from '../../../../models/profession.model';
import { sortBy } from '../../../../enums/sort-by.enum';
import { filterType } from '../../../../enums/filter-type.enum';
import { IdValue } from '../../../../models/id-value.model';
import { FormControl } from '@angular/forms';



@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrl: './task-list.component.css'
})
export class TaskListComponent implements OnInit {

  //---------------attributes--------------

  public sortBy = sortBy;
  public filterType = filterType ;
  creatorNames: { firstName: string, lastName: string, showLastName: boolean }[] = [];
  professions: Profession[] = [];
  tasks: WorkTask[] = [];
  quickSearchControl = new FormControl('');
  descriptionControl = new FormControl('');

  professionId: string = "";
  availableTags: string[] = [];
  availableExperts: IdValue[] = [];
  isFilterModalOpen: boolean = false;
  filter: WorkTaskFilter = new WorkTaskFilter("",Array(),Array(),"");
  SelectedSortBy : sortBy | null = null;
  //all temp are for front part
  // temp -> for not displaying change went press
  tempTags : string[] = [];
  tempExpert : IdValue[] = [];
  tempDescription :  string = "";
  tempSelectedSortBy : sortBy | null = null;

  isSectionOpen : { [key: string]: boolean } = {
    metier: true,
    creator: false,
    sortBy: false,
    description : false,
    title: false,
   };

  imageUrls: { [key: string]: SafeUrl } = {};
  constructor(private professionService: ProfessionService, private route: ActivatedRoute, private router: Router, private taskService: WorkTaskService, private sanitizer: DomSanitizer) {
    this.quickSearchControl.valueChanges.subscribe(value => {
      this.filter.quickSearch = value || '';
      this.getTasks();
    });

    this.descriptionControl.valueChanges.subscribe(value => {
      this.tempDescription = value || '';
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('professionId') == undefined)
      return;
    this.professionId= this.route.snapshot.paramMap.get('professionId')!;
    // Charger la liste des tâches en fonction de la profession
    this.getTasks();
    this.getTags();
    this.getExperts();
  }

//-----------------get---------------
getTags() {
  this.professionService.getTags(this.professionId).subscribe((data) => {
    this.availableTags = data;
  });
}

getExperts() {
  this.professionService.getExperts(this.professionId).subscribe((data) => {
    this.availableExperts = data;
  });
}

getExpertValues(): string {
  return this.filter.experts.map(expert => expert.value).join(', ');
}

getTasks(): void {
  if (this.professionId) {
    this.professionService.getTasks(this.professionId, this.filter)
      .pipe(
        tap(data => {
          this.tasks = data;
          if (this.SelectedSortBy) {
            this.tasks = this.sortTasks(this.tasks, this.SelectedSortBy);
          }
        }),
        switchMap(() => {
          const imageObservables = this.tasks.map(task =>
            this.taskService.getImage(task.id).pipe(
              map(imageBlob => {
                const objectUrl = URL.createObjectURL(imageBlob);
                return { id: task.id, url: this.sanitizer.bypassSecurityTrustUrl(objectUrl) };
              })
            )
          );
          return forkJoin(imageObservables); 
        })
      )
      .subscribe({
        next: results => {
          // Associer les URLs d'images aux tâches
          results.forEach(result => {
            this.imageUrls[result.id] = result.url;
          });
        },
        error: err => console.error('Erreur lors du chargement des tâches ou des images:', err)
      });
  }
}

  getProfessions(): void {
    this.professionService.getProfessions().subscribe((data: Profession[]) => {
      this.professions = data;
    });

  }

  //-------------------filter-------------------
  closeFilterModal(): void {
    this.isFilterModalOpen = false;
  }


  applyFilters(): void {
    this.SelectedSortBy = this.tempSelectedSortBy;
    this.filter.description = this.tempDescription;
    this.filter.tags = this.tempTags;
    this.filter.experts = this.tempExpert;
    this.getTasks();
    this.closeFilterModal();
  }

  /// function for clearing a filter
clearFilter(filterTypes: filterType): void {
  switch (filterTypes) {
    case filterType.Tags:
      this.filter.tags = [];
      this.tempTags = [];
      break;
    case filterType.Description:
      this.filter.description = "";
      this.tempDescription = "";
      break;
    case filterType.Expert:
      this.filter.experts = [];
      this.tempExpert = [];
      break;
    case filterType.SortBy:
      this.SelectedSortBy = null;
      break;
    default:
      break;
  }

  this.getTasks();
}

  // sort function
  sortTasks(tasks: WorkTask[], SortBy: sortBy): WorkTask[] {
    switch (SortBy) {
      case sortBy.New:
        return tasks.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      case sortBy.Old:
        return tasks.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime());
      case sortBy.MostView:
        return tasks.sort((a, b) => b.participation - a.participation);
      case sortBy.AlphaDown:
        return tasks.sort((a, b) => a.name.localeCompare(b.name));
      case sortBy.AlphaUp:
        return tasks.sort((a, b) => b.name.localeCompare(a.name));
      default:
        return tasks;
    }
  }

  //----------toggle-------------

  // Function to handle accordion display
  toggleSection(section: string): void {
    this.isSectionOpen[section] = !this.isSectionOpen[section];
  }

  toggleFilterModal(): void {
    this.isFilterModalOpen = !this.isFilterModalOpen;
  }

  onTagsSelectionChange(selected: string[]) {
    this.tempTags= selected;
  }

  onExpertsSelectionChange(selected: IdValue[]) {
    this.tempExpert = selected;
  }

  onLearnClick(task: WorkTask): void {
    this.router.navigate(['/training/learning/profession', this.professionId, task.id]);
  }

  onSelectSortBy(sortBy: sortBy): void {
    this.tempSelectedSortBy = this.tempSelectedSortBy === sortBy ? null : sortBy;
  }

}

