import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ProfessionService } from '../../../../services/training/profession.service';
import { Profession } from '../../../../models/profession.model';
import { WorkTaskService } from '../../../../services/training/work-task.service';
import { FileService } from '../../../../services/file/file.service';
import { CogeoDifficultyEnum } from 'src/app/enums/cogeo-difficulty.enum';
import { CogeoApproachEnum } from 'src/app/enums/cogeo-approach.enum';
import { WorkTaskInit } from 'src/app/models/work-task-init.model';
import { FormControl } from '@angular/forms';
import { EMPTY,merge,of } from 'rxjs';
import { catchError, switchMap, map, tap } from 'rxjs/operators';
@Component({
  selector: 'app-import-video',
  templateUrl: './import-video.component.html',
  styleUrls: ['./import-video.component.css']
})
export class ImportVideoComponent {
  public isFileValid: boolean = false;
  public selectedFile: File | null = null;
  public isUploading: boolean = false;
  public uploadProgress: number = 0;
  private MAX_FILE_SIZE: number = 20000 * 1024 * 1024; // 20GB
  public selectedProfessionId: string = "";
  public professions: Profession[] = [];

  public difficulties = Object.entries(CogeoDifficultyEnum)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({ label: key, value: Number(value) }));

  public approaches = Object.entries(CogeoApproachEnum)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({ label: key, value: Number(value) }));

  public selectedApproach: FormControl = new FormControl(CogeoApproachEnum["Lean"]);
  public selectedDifficulty: FormControl = new FormControl(CogeoDifficultyEnum["Confirmé"]);
  public fileLink:  FormControl = new FormControl("");

constructor(private router: Router, private professionService : ProfessionService, private taskService : WorkTaskService, private fileService: FileService ) { }

  ngOnInit(): void {
    this.professionService.getProfessions().subscribe(professions => {
      this.professions = professions;
    });
  }
  selectVideo() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';

    input.onchange = (event: any) => {
      this.fileLink.setValue("");
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.validateFile(file);
      }
    };

    input.click();
  }

  validateFile(file: File) {
    if (file.type.startsWith('video/') && file.size <= this.MAX_FILE_SIZE) {
      this.isFileValid = true;
    } else {
      this.isFileValid = false;
    }
  }

  resetFile()
  {
    this.selectedFile = null;
    this.isFileValid = false;
  }

  onFileLinkInput() {
    if (this.fileLink.value.trim() !== "") {
      this.resetFile();
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer==undefined || event.dataTransfer.files.length <= 0)
     return;

    const file: File = event.dataTransfer.files[0];
    this.selectedFile = file;
    this.validateFile(file);
  }

  onCancel()
  {
    this.router.navigate(['/']);
  }

  onNext() {
    if ((!this.selectedFile || !this.isFileValid) && this.fileLink.value.trim() === '') {
      return;
    }

    this.professionService.getDefaultProfessionId().pipe(
      switchMap((professionId) => {
        this.selectedProfessionId = professionId;
        return this.professionService.initializeWorkTask(
          professionId,
          new WorkTaskInit(this.selectedDifficulty.value, this.selectedApproach.value)
        ).pipe(
          map(data => ({ professionId, workTaskId: data.id }))
        );
      }),
      switchMap(({ professionId, workTaskId }) =>
        this.taskService.InitializeWorkTaskFile(workTaskId).pipe(
          map(dataInitializeWorkTaskFile => ({
            professionId,
            workTaskId,
            fileId: dataInitializeWorkTaskFile.id
          }))
        )
      ),
      switchMap(({ professionId, workTaskId, fileId }) => {
        const uploadAction$ = this.fileLink.value.trim() !== ''
          ? this.fileService.uploadWorkTaskFileFromUrl(this.fileLink.value.trim(), fileId)
          : this.fileService.uploadWorkTaskFile(this.selectedFile!, fileId);

        const navigation$ = of(null).pipe(
          tap(() => {
            if (this.fileLink.value.trim() !== '') {
              this.moveToNextStepFromLink(professionId, workTaskId, fileId);
            } else {
              this.moveToNextStepFromFile(professionId, workTaskId, fileId);
            }
          })
        );

        return merge(
          uploadAction$.pipe(catchError(() => EMPTY)),
          navigation$
        );
      })
    ).subscribe();
  }





  moveToNextStepFromFile(professionId: string, workTaskId: string, workTaskFileId: string) {
    if (this.selectedFile?.name) {
      this.router.navigate([
        'training/creation/play-video',
        professionId,
        workTaskId,
        workTaskFileId,
        encodeURIComponent(URL.createObjectURL(this.selectedFile)),
      ]);
    }
  }

  moveToNextStepFromLink(professionId: string, workTaskId: string, workTaskFileId: string){
    if (this.fileLink.value.trim()!='')
    {
      this.router.navigate(['training/creation/play-video',
        professionId,
        workTaskId,
        workTaskFileId,
        encodeURIComponent(environment.apiUrl + "videoproxy/stream-video?videoUrl="+this.fileLink.value.trim())]);
    }
  }
}

