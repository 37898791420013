<div class="search-container">
  <i class="fas fa-search search-icon"></i>
  <input
  type="text"
  class="search-input"
  placeholder="Recherche ..."
  [formControl]="quickSearchControl"
/>

  <!-- button and filter window-->
  <div class="filter-wrapper">
    <div class="filter-button" (click)="toggleFilterModal()">
      <i class="fa-solid fa-sliders" style="color: #ffffff"></i>
    </div>

    <!-- filter window -->
    <div class="filter-modal" *ngIf="isFilterModalOpen">
      <div class="filter-modal-content">
        <h2>Filtre</h2>

        <div class="filter-options">
          <!-- jobs part -->
          <p (click)="toggleSection('tags')" class="accordion-header">
            Tags
            <i
              [class.rotated]="isSectionOpen['tags']"
              class="fa-solid fa-chevron-up arrow"
            ></i>
          </p>
          <div *ngIf="isSectionOpen['tags']" class="filter-buttons">
            <app-autocomplete-bubbles
            [fillings]="availableTags"
            [(mySelection)]="tempTags"
            (mySelectionChange)="onTagsSelectionChange($event)"
          ></app-autocomplete-bubbles>

          </div>
          <!-- creator part -->
          <p
            (click)="toggleSection('creator')"
            class="accordion-header"
            style="margin-top: 20px"
          >
            Créateur
            <i
              [class.rotated]="isSectionOpen['creator']"
              class="fa-solid fa-chevron-up arrow"
            ></i>
          </p>
          <div class="filter-buttons" *ngIf="isSectionOpen['creator']">
            <app-autocomplete-bubbles-couple-values
              [fillings]="availableExperts"
              [(mySelection)]="tempExpert"
              (mySelectionChange)="onExpertsSelectionChange($event)"
            ></app-autocomplete-bubbles-couple-values>
          </div>


          <p
            (click)="toggleSection('description')"
            class="accordion-header"
            style="margin-top: 20px"
          >
            Description
            <i
              [class.rotated]="isSectionOpen['description']"
              class="fa-solid fa-chevron-up arrow"
            ></i>
          </p>
          <div *ngIf="isSectionOpen['description']" class="filter-buttons">
            <input
              type="text"
              id="descriptionFilter"
              [formControl]="descriptionControl"
            />
          </div>

          <!-- sortby part -->
          <p
            (click)="toggleSection('sortBy')"
            class="accordion-header"
            style="margin-top: 20px"
          >
            Trier par
            <i
              [class.rotated]="isSectionOpen['sortBy']"
              class="fa-solid fa-chevron-up arrow"
            ></i>
          </p>

          <div class="sortBy-buttons" *ngIf="isSectionOpen['sortBy']">
            <label
              [class.active]="tempSelectedSortBy === sortBy.New"
              (click)="onSelectSortBy(sortBy.New)"
            >
              Nouveaux

              <i class="fa-solid fa-arrow-down-wide-short"></i>
            </label>
            <label
              [class.active]="tempSelectedSortBy === sortBy.Old"
              (click)="onSelectSortBy(sortBy.Old)"
            >
              Anciens

              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </label>

            <label
              [class.active]="tempSelectedSortBy === sortBy.MostView"
              (click)="onSelectSortBy(sortBy.MostView)"
            >
              Plus vus

              <i class="fa-solid fa-eye"></i>
            </label>

            <label
              [class.active]="tempSelectedSortBy === sortBy.AlphaDown"
              (click)="onSelectSortBy(sortBy.AlphaDown)"
            >
              Alphabétique A-Z

              <i class="fa-solid fa-arrow-up-a-z"></i>
            </label>

            <label
              [class.active]="tempSelectedSortBy === sortBy.AlphaUp"
              (click)="onSelectSortBy(sortBy.AlphaUp)"
            >
              Alphabétique Z-A

              <i class="fa-solid fa-sort-alpha-down"></i>
            </label>
          </div>
          <!-- apply filter -->
          <button (click)="applyFilters()">Appliquer les filtres</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div
  class="active-filters-container"
>


<span *ngIf="filter.tags.length > 0" class="filter-label">
  {{filter.tags.join(', ')}}
  <i class="fa-solid fa-times" (click)="clearFilter(filterType.Tags)"></i>
</span>

<span *ngIf="filter.description" class="filter-label">
  {{ filter.description }}
  <i class="fa-solid fa-times" (click)="clearFilter(filterType.Description)"></i>
</span>

<span *ngIf="filter.experts.length > 0" class="filter-label">
    {{ getExpertValues() }}
    <i class="fa-solid fa-times" (click)="clearFilter(filterType.Expert)"></i>
  </span>

</div>




<div class="task-list-container">
  <div
    class="task-card"
    *ngFor="let task of tasks"
    (click)="onLearnClick(task)"
  >
    <div class="task-info">
      <div class="task-info-top">
        <p class="taskname">{{ task.name }}</p>
        <p class="description">{{ task.description }}</p>
      </div>
      <div class="task-info-bottom">
        <div class="task-details-left">
          <p class="date">{{ task.creationDate | date : "dd/MM/yyyy" }}</p>
          <p class="tags"><span class="tag-icon"></span>{{ task.tags }}</p>
        </div>
      </div>
    </div>
    <div class="task-image">
      <img *ngIf="imageUrls[task.id]" [src]="imageUrls[task.id]" alt="Image" />
      <div class="task-expert">
        <p>{{ task.creatorUser.firstName }} {{ task.creatorUser.lastName }}</p>
      </div>
    </div>
  </div>
</div>
