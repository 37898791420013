import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { VideoPlayerService } from '../../services/video-player/video-player.service';
import { FileService } from '../../services/file/file.service';
import { FormControl } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.css'
})
export class VideoPlayerComponent {
  @Input() videoFileParam: string | undefined;
  @Input() videoWorkTaskFileIdParam: string | undefined;
  @Input() videoUrl: string = '';
  @Output() currentTimeBeforeChange = new EventEmitter<number>();

  videoUrlControl = new FormControl('');

  public isVideoLoaded: boolean = false;
  public videoSource: string = '';
  public isPlaying: boolean = false;
  public isVideoFinished: boolean = false;
	public video_volume: number = 0.5;
  public currentTime: number = 0;
  @ViewChild('videoPlayer', { static: false }) videoPlayer!: ElementRef<HTMLVideoElement>;

  constructor(private videoPlayerService: VideoPlayerService, private fileService: FileService) {
    this.videoUrlControl.valueChanges.subscribe(value => {
      this.videoUrl = value || '';
    });
  }

  ngAfterViewInit(): void {
    if (this.videoWorkTaskFileIdParam) {
      firstValueFrom(this.fileService.filesInProgress$).then(files => {
        if (files.length === 0) { // Aucun fichier en cours de téléchargement
          this.setVideoFromWorkTaskFile(this.videoWorkTaskFileIdParam!);
        } else {
          if (this.videoFileParam) {
            this.videoUrl = this.videoFileParam;
            this.setVideoFromGivenUrl();
          }
        }
      }).catch(error => {
        console.error('Erreur lors de la récupération des fichiers en cours :', error);
      });
    } else {
      if (this.videoFileParam) {
        this.videoUrl = this.videoFileParam;
        this.setVideoFromGivenUrl();
      }
    }
   
    this.videoPlayerService.addLoadedListener(this.videoPlayer.nativeElement, () => {
        this.isVideoLoaded = true;
    });

    this.videoPlayer.nativeElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
  }

  private onTimeUpdate(event: any): void {
    this.currentTime = this.getCurrentTime();
  }

  public getCurrentTime(){
    return this.videoPlayer.nativeElement.currentTime
  }

  public getHtmlVideoComponent(){
    return this.videoPlayer.nativeElement;
  }
  public setVideoFromGivenUrl() {
    this.videoPlayerService.getVideoStream(this.videoUrl).subscribe((response: Blob) => {
      const videoUrl = URL.createObjectURL(response);
      this.videoPlayer.nativeElement.src = videoUrl;
    });
  }

  public setVideoFromWorkTaskFile(workTaskFileId: string) {
    this.fileService.getVideoUrl(workTaskFileId).subscribe((response) => {
      this.videoPlayer.nativeElement.src = response.url;
    });
  }

  public backwardInSeconds(nbSeconds : number) {
    this.videoPlayer.nativeElement.currentTime-=nbSeconds;
  }

  public forwardInSeconds(nbSeconds : number) {
    this.currentTimeBeforeChange.emit(this.videoPlayer.nativeElement.currentTime);
    this.videoPlayer.nativeElement.currentTime+=nbSeconds;
  }

  public seekTo(event: any): void {
    const seekTime = event.target.value;
    this.videoPlayer.nativeElement.currentTime = seekTime;
  }

  public play() {
    if (!this.isVideoLoaded)
    {
      return;
    }
    this.videoPlayerService.setPlayerStatus("play");
    this.isVideoFinished = false;
    this.videoPlayer.nativeElement.play();
    this.isPlaying = true;
  }

  public pause() {
    this.videoPlayerService.setPlayerStatus("pause");
    this.videoPlayer.nativeElement.pause();
    this.isPlaying = false;
  }

  public goToVideoTime(time : number)
  {
    this.videoPlayer.nativeElement.currentTime = time;
  }

  public fullScreen(): void {
    if (this.isFullscreen()) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  }

  private isFullscreen(): boolean {
    return document.fullscreenElement != null;
  }

  private enterFullScreen(): void {
    if (this.videoPlayer.nativeElement.requestFullscreen) {
      this.videoPlayer.nativeElement.requestFullscreen();
    };
  }

   private exitFullScreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    };
  }
}
