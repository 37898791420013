// cogeo.service.ts
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, timer, of, interval } from 'rxjs';
import { map, catchError, switchMap, filter, take, tap, finalize, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { CogeoMessageAnswer } from '../../models/cogeo-message.answer.model';
import { TimeComment } from '../../models/time-comment.model';
import { Router } from '@angular/router';
import { GenericPopinService } from '../generic-popin/generic-popin.service';
import { WaitingPopinService } from '../waiting-popin/waiting-popin.service';
import { ProfessionService } from '../training/profession.service';
import { WorkTaskService } from '../training/work-task.service';
import { CogeoStatusEnum } from 'src/app/enums/cogeo-status.enum';
import { CogeoGenerationStatus } from 'src/app/models/cogeo-generation-status.model';

@Injectable({
  providedIn: 'root'
})
export class CogeoService {
  private apiUrl = environment.apiUrl + 'cogeo';
  private cogeoWebSocketUrl = environment.cogeoWebSocketUrl;
  private socket!: WebSocket;
  private messagesSubject = new Subject<CogeoMessageAnswer>();
  public messages$ = this.messagesSubject.asObservable();
  private chatEndedSubject = new Subject<boolean>();
  public chatEnded$ = this.chatEndedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private finalPopin: GenericPopinService,
    private professionService: ProfessionService,
    private workTaskService: WorkTaskService,
    private waitingPopin: WaitingPopinService
  ) {}

  private createWebSocketConnection(cogeoComments: any): Observable<CogeoMessageAnswer> {
    return new Observable(observer => {
      this.socket = new WebSocket(this.cogeoWebSocketUrl + "/start_analysis");

      this.socket.onopen = () => {
        this.socket.send(JSON.stringify(cogeoComments));
      };

      this.socket.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        observer.next(messageData);
      };

      this.socket.onerror = (error) => {
        observer.error(error);
        this.socket.close();
      };

      return () => {
        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.close();
        }
      };
    });
  }

  public initializeAnalysis(
    professionId: string,
    workTaskId: string,
    workTaskFileId: string,
    videoComments: TimeComment[]
  ): void {
    const endChatMessage = "Analysis complete";

    this.workTaskService.getTaskDetail(workTaskId).pipe(
      switchMap(task => {
        const cogeoComments = {
          comments: videoComments.map(v => v.modifiedComment || v.comment).join(' '),
          approach: task.cogeoApproachId,
          level: task.cogeoLevelId
        };

        return this.createWebSocketConnection(cogeoComments);
      }),
      takeUntil(this.chatEndedSubject)
    ).subscribe({
      next: (messageData: CogeoMessageAnswer) => {
        this.messagesSubject.next(messageData);
        if (messageData.message === endChatMessage) {
          this.chatEndedSubject.next(true);
          this.showFinalPopin(professionId, workTaskId, workTaskFileId, messageData.task_id);
        }
      },
      error: (error) => console.error('WebSocket error:', error)
    });
  }

  public sendMessage(message: string): void {
    const payload = { answer: message };
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(payload));
    }
  }

  public showFinalPopin(professionId: string, workTaskId: string, workTaskFileId: string, cogeoTaskId: number): void {
    this.finalPopin.open(
      'L\'échange avec Cogeo est maintenant terminé, nous pouvons générer le contenu',
      () => this.generateFirstCourse(professionId, workTaskId, cogeoTaskId, workTaskFileId)
    );
  }

  private checkStatus(
    workTaskId: string,
    cogeoTaskId: number,
    workTaskFileId: string,
    cogeoJobId: string
  ): Observable<boolean> {
    const params = new HttpParams({
      fromObject: {
        workTaskId,
        workTaskFileId,
        cogeoTaskId,
        cogeoJobId
      }
    });

    return this.http.get<CogeoGenerationStatus>(`${this.apiUrl}/checkGenerationStatus`, { params }).pipe(
      map((response: CogeoGenerationStatus) => {
        if (response.status === CogeoStatusEnum.Completed) {
          return true;
        } else if (response.status === CogeoStatusEnum.Failed) {
          throw new Error("Generation failed");
        }
        return false;
      }),
      catchError((error) => {
        console.error("Generation status check failed:", error);
        return of(false);
      })
    );
  }

  private pollGenerationStatus(
    workTaskId: string,
    cogeoTaskId: number,
    workTaskFileId: string,
    cogeoJobId: string
  ): Observable<boolean> {
    return timer(0, 60000).pipe(
      switchMap(() => this.checkStatus(workTaskId, cogeoTaskId, workTaskFileId, cogeoJobId)),
      filter(status => status === true),
      take(1),
      catchError(error => {
        console.error('Error polling generation status:', error);
        return of(false);
      })
    );
  }

  public generateFirstCourse(
    professionId: string,
    workTaskId: string,
    cogeoTaskId: number,
    workTaskFileId: string
  ): void {
    this.waitingPopin.showPopin("Génération en cours");

    const payload = {
      workTaskId,
      workTaskFileId,
      cogeoTaskId
    };

    this.http.post(`${this.apiUrl}/generateFirstCourse`, payload).pipe(
      switchMap((response: any) =>
        timer(120000).pipe(
          switchMap(() => this.pollGenerationStatus(
            workTaskId,
            cogeoTaskId,
            workTaskFileId,
            response.job_id
          ))
        )
      ),
      finalize(() => this.waitingPopin.hidePopin())
    ).subscribe({
      next: (success) => {
        if (success) {
          this.finalizeGeneration(professionId, workTaskId);
        }
      },
      error: (error) => {
        console.error('Course generation error:', error);
      }
    });
  }

  private finalizeGeneration(professionId: string, workTaskId: string): void {
    this.professionService.getDefaultProfessionId().pipe(
      tap(() => {
        this.router.navigate(['training/learning/profession/', professionId, workTaskId]);
      })
    ).subscribe();
  }

  public synthesizeText(message: string): Observable<Blob> {
    return this.http.get<Blob>(
      `${this.apiUrl}/synthesize?text=${encodeURIComponent(message)}`,
      { responseType: 'blob' as 'json' }
    );
  }
}
