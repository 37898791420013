<div class="main-container">
  <div class="video-container">
    <div [hidden]="isVideoLoaded || videoFileParam">
      <label for="name"><b>Video URL:</b></label>
      <input type="text" id="videoUrl"  [formControl]="videoUrlControl"  width="1000" />
      <button class="btn-primary" (click)="setVideoFromGivenUrl()">Load Video</button>
    </div>

    <div class="video-wrapper">
      <video #videoPlayer playsinline class="videoComponent">
        <source [src]="videoSource">
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="progress-container">
      <span [hidden] = "!isVideoLoaded" class="timestamp-left">{{ currentTime | formatTime }} / {{ videoPlayer.duration | formatTime}}</span>
      <input
        type="range"
        min="0"
        [max]="videoPlayer.duration"
        [value]="currentTime"
        (input)="seekTo($event)"
        class="progress-bar"
      />
    </div>
  </div>

  <div class="commande-panel">
    <button class="btn-round" (click)="backwardInSeconds(10)" title="Recul de 10 secondes" style="margin-left: 10px;">
      <i class="fas fa-backward"></i>
    </button>
    <button class="btn-round" (click)="play()" [hidden]="isPlaying" style="margin-left: 10px;">
      <i class="fas fa-play"></i>
    </button>
    <button class="btn-round" (click)="pause()" [hidden]="!isPlaying" style="margin-left: 10px;">
      <i class="fas fa-pause"></i>
    </button>
    <button class="btn-round" (click)="forwardInSeconds(30)" title="Avance de 30 secondes" style="margin-left: 10px;">
      <i class="fas fa-forward"></i>
    </button>
    <button class="btn-round" (click)="fullScreen()" title="Plein écran" style="margin-left: 10px;">
      <i class="fas fa-expand"></i>
    </button>
  </div>
</div>
