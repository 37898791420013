import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-autocomplete-bubbles',
  templateUrl: './autocomplete-bubbles.component.html',
  styleUrls: ['./autocomplete-bubbles.component.css']
})
export class AutocompleteBubblesComponent implements OnInit {
  @Input() fillings: string[] = [];
  @Input() mySelection: string[] = [];
  @Output() mySelectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  wordInputControl = new FormControl('');
  filteredOptions: string[] = [];

  ngOnInit() {
    this.wordInputControl.valueChanges.subscribe(value => {
      this.filterOptions(value || '');
    });

    this.filterOptions('');
  }


  filterOptions(value: string) {
    if (!value) {
      this.filteredOptions = this.fillings;
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.fillings.filter(option => option.toLowerCase().includes(filterValue));
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.mySelection.includes(value) && this.fillings.includes(value)) {
      this.mySelection.push(value);
      this.mySelectionChange.emit(this.mySelection);
    }
    event.input.value = '';
    this.wordInputControl.setValue('');
  }

  remove(filling: string): void {
    const index = this.mySelection.indexOf(filling);
    if (index >= 0) {
      this.mySelection.splice(index, 1);
      this.mySelectionChange.emit(this.mySelection);
    }
  }

  selectOption(option: string): void {
    if (!this.mySelection.includes(option)) {
      this.mySelection.push(option);
      this.clearInput();
    }
  }

  clearInput(): void {
    this.wordInputControl.setValue('');
    this.filteredOptions = [];
    this.mySelectionChange.emit(this.mySelection);
  }


  handleKeydown(event: KeyboardEvent): void {
    if ((event.key === 'Enter' || event.key === 'Tab') && this.filteredOptions.length > 0) {
      event.preventDefault();
      this.selectOption(this.filteredOptions[0]);
    }
  }

  trackByFn(index: number, item: string): string {
    return item;
  }
}
