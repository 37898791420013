import { Component, ViewChild,  Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeComment } from '../../../../models/time-comment.model';
import { VideoPlayerComponent } from '../../../../shared_components/video-player/video-player.component';
import { FileService } from '../../../../services/file/file.service';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { GenericPopinService } from '../../../../services/generic-popin/generic-popin.service';
@Component({
  selector: 'app-video-cogeo-chat',
  templateUrl: './video-cogeo-chat.component.html',
  styleUrl: './video-cogeo-chat.component.css'
})
export class VideoCogeoChatComponent {
  isModalVisible: Observable<boolean>;
  modalContent: Observable<string | null>;
  isCommentOpen = false;
  isChatOpen = true;
  workTaskFileId: string = "";
  workTaskId: string = "";
  professionId: string = "";
  filePath: string = "";
  videoFile: string = "";
  cogeoTaskId: number = -1;
  currentVideoTime : number = 0;
  private currentTimeInterval: any;
  private modalSubscription: Subscription | undefined;
  @Input() comments : TimeComment[] = [];
  @ViewChild('videoPlayerComponent', { static: false }) videoPlayerComponent!: VideoPlayerComponent;

  constructor(
    private fileService : FileService,
    private route: ActivatedRoute,
    private router: Router,
    private popinService: GenericPopinService)
    {
      this.isModalVisible = this.popinService.getModalState();
      this.modalContent = this.popinService.getContent();
    }

    ngOnInit(): void {
      this.route.paramMap
        .pipe(
          tap(params => {
            const filePath = params.get('filePath');
            const workTaskFileId = params.get('workTaskFileId');
            const workTaskId = params.get('workTaskId');
            const professionId = params.get('professionId');
            const cogeoTaskId = params.get('cogeoTaskId');

            this.workTaskFileId = workTaskFileId ?? "";
            this.workTaskId = workTaskId ?? "";
            this.professionId = professionId ?? "";
            this.filePath = filePath ?? "";
            this.cogeoTaskId = cogeoTaskId ? parseInt(cogeoTaskId) : -1;
            this.videoFile = decodeURIComponent(this.filePath);
          }),
          switchMap(() => this.fileService.getComments(this.workTaskFileId)),
          tap(data => {
            this.comments = data;
          })
        )
        .subscribe({
          error: (err) => console.error('Erreur lors de la récupération des commentaires :', err)
        });
      this.currentTimeInterval = setInterval(() => {
        this.currentVideoTime = this.getCurrentTime();
      }, 1000);
      this.modalSubscription = this.isModalVisible.subscribe(isVisible => {
        if (isVisible) {
          this.closePanels();
        }
      });
    }

  public goToVideoTime(time: number)
  {
    this.videoPlayerComponent.goToVideoTime(time);
  }

  public onCancel()
  {
    this.router.navigate(['/']);
  }

  public getCurrentTime()
  {
    return this.videoPlayerComponent.getCurrentTime();
  }

  public closePopin()
  {
    this.popinService.close();
  }

  ngOnDestroy(): void {
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }

    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
  }

  toggleComment() {
    this.isCommentOpen = !this.isCommentOpen;
  }

  closePanels(){
    this.isCommentOpen = false;
    this.isChatOpen = false;
  }

}
